// ./
import dataformElements from './dataform.elements'
import { parseDataResponse, parseStackedDataResponse } from './response.parser'

// src
import Config from '@/config'

// Classes
import { JoiManager }     from '@/Classes/Network/JoiManager'
import { ActionField }    from '@/Classes/Records/ActionField'
import { DataParsers }    from '@/Classes/Responses/DataParsers'
import { PrimitiveTools } from '@/Classes/Static/PrimitiveTools'
import { RutTools }       from '@/Classes/Static/RutTools'
import { VuexTools }      from '@/Classes/Static/VuexTools'

// Components (.vue)
import BasicHeader     from '@/Components/Global/BasicHeader/template.vue'
import DataTable       from '@/Components/Global/DataTable/template.vue'
import PopupUserForm   from '@/Components/Global/PopupUserForm/template.vue'
import AdminNavigation from '@/Components/Modules/2/AdminNavigation/template.vue'
import PermissionsForm from '@/Components/Modules/2/PermissionsForm/template.vue'

// Components (Refs)
import { BasicHeaderRef }     from '@/Components/Global/BasicHeader/component'
import { DataFormRef }        from '@/Components/Global/DataForm/component'
import { DataTableRef }       from '@/Components/Global/DataTable/component'
import { PopupUserFormRef }   from '@/Components/Global/PopupUserForm/component'
import { PermissionsFormRef } from '@/Components/Modules/2/PermissionsForm/component'

// Constants
import { AppValues }   from '@/Constants/Global/AppValues'
import { Breakpoints } from '@/Constants/Global/Breakpoints'
import { Component }   from '@/Constants/Global/Component'
import { Documents }   from '@/Constants/Global/Documents'
import { Server }      from '@/Constants/Global/Server'
import { VueRouter }   from '@/Constants/Global/VueRouter'
import { Vuex }        from '@/Constants/Global/Vuex'
import { Module2 }     from '@/Constants/Modules/Module2'

// Dependencies
import VueMixins from 'vue-typed-mixins'

// Helpers
import { InputPhone } from '@/Helpers/Components/DataForm/InputPhone'

// Mixins
import MixinBase       from '@/Mixins/MixinBase'
import MixinComponent  from '@/Mixins/MixinComponent'
import MixinFetch      from '@/Mixins/MixinFetch'
import MixinResponsive from '@/Mixins/MixinResponsive'

// Store
import Store from '@/Store/Global/Default'

// Component Extend
const View21 = VueMixins(MixinBase, MixinComponent, MixinFetch, MixinResponsive).extend({
	name: VueRouter.Modules.View21.NAME,

	components: {
		AdminNavigation,
		BasicHeader,
		DataTable,
		PermissionsForm,
		PopupUserForm
	},

	data: function() {
		return {
			states: {
				dataFormAction: Component.Actions.INSERT,
				dataTableParser: parseDataResponse,
				documentToUpdate: undefined as any,
				showDataTable: true,
				showPermissionsForm: false,
				showPopupUserForm: false,
				userStorages: [] as Array<any>
			}
		}
	},

	mounted: function() {
		this._initDataTable()
		this._initDataForm()
		this._initPermissions()

		// Deshabilitar comportamiento de Busqueda Local y Tipo de Descarga para el componente 'DataTable'.
		this._dataTable.setStates<DataTableRef['states']>({
			exportDataAsAsync: true,
			exportDataFilterType: AppValues.DataTableExportDataFilterType.QUANTITY,
			isLocalSearch: false,
			preventDefaultStacked: true
		})

		// Componente PopupTable/DataTable.
		this._nestedDataTable.setStates<DataTableRef['states']>({ isLocalSearch: false, preventDefaultStacked: true })
		this._nestedDataTable.setSelectable(true)

		// Component PopupUserForm/DataTable.
		this._dataTable.setStates<DataTableRef['states']>({ preventDefaultStacked: true })
		this._popupUserForm._dataTable.setStates<DataTableRef['states']>({ preventDefaultStacked: true, showRefreshButton: false })
	},

	computed: {
		_basicHeader: function(): BasicHeaderRef {
			return this.$refs.basicHeader as BasicHeaderRef
		},

		_dataForm: function(): DataFormRef {
			return this._popupUserForm.$refs.dataForm as DataFormRef
		},

		_dataTable: function(): DataTableRef {
			return this.$refs.dataTable as DataTableRef
		},

		_nestedDataTable: function(): DataTableRef {
			return this._popupUserForm._dataForm._popupTable._dataTable as DataTableRef
		},

		_permissionsForm: function(): PermissionsFormRef {
			return this.$refs.permissionsForm as PermissionsFormRef
		},

		_popupUserForm: function(): PopupUserFormRef {
			return this.$refs.popupUserForm as PopupUserFormRef
		}
	},

	methods: {
		_initDataForm: function() {
			const { _dataForm } = this._popupUserForm
			_dataForm.initialize(dataformElements)
		},

		_initDataTable: async function(page = 1, forceRefresh = false) {
			const response = await Store.dispatch('fetchUsersForPage', { forceRefresh, isClient: false, page, type: 'Internal' })
			const { fields, items, actions } = this.states.dataTableParser(response.data)
			this._dataTable.updateElementsAndPagination(response.totalPages, fields, items, actions)
		},

		_initPermissions: function() {
			const userPermissions = Store.getters.getStoredUserPermissionsAsObject
			this._dataTable.setPermission('ACTION_EDIT', userPermissions.MODULE_20_MANAGE_INTERNAL_USERS?.privileges.write)
			this._dataTable.setPermission('NEW_BUTTON', userPermissions.MODULE_20_MANAGE_INTERNAL_USERS?.privileges.write)
		},

		_initPopupUserFormDataTable: function(storages: Array<any>) {
			if (storages.length > 0) {
				// Acción para permitir remover la Ubicación de la Tabla.
				const _actions = [
					new ActionField('actions', 'Eliminar').addItem('delete', 'icon').setIcon('times-circle').setPermission('ACTION_DELETE').setVariant('red')
				]
				
				// Convertir los datos para mostrarlos en el <DataTable>.
				const SummaryParser = DataParsers.Storages.GetSummaryParser(this._popupUserForm._dataTable.states.stacked)
				const { fields, items, actions } = SummaryParser(storages, _actions)
				this._popupUserForm._dataTable.setElements(fields, items, actions)
				return
			}
			this._popupUserForm._dataTable.clearData()
		},

		_initRoles: async function() {
			// Limpiar el array para evitar duplicidad de opciones.
			const { _dataForm } = this._popupUserForm
			_dataForm.clearOptions('role')

			// Generar las opciones del 'Select' de 'Roles'.
			const roles = await Store.dispatch('fetchRoles')
			if (Array.isArray(roles)) {
				for (const role of roles) {
					if (role._idRole === Documents.Roles.Kreis.ROOT && Config.entries.removeRootRole) continue
					if (role._idRole === Documents.Roles.Kreis.CLIENT) continue
					_dataForm.addOption('role', { value: role._idRole, text: role.name })
				}
			}
		},

		_initWorkAreas: async function() {
			// Limpiar el array para evitar duplicidad de opciones.
			const { _dataForm } = this._popupUserForm
			_dataForm.clearOptions('workarea')

			// Generar las opciones del 'Select' de 'WorkAreas'.
			const workareas = await Store.dispatch('fetchAllWorkAreas')
			if (Array.isArray(workareas)) {
				for (const workarea of workareas) {
					if (workarea._idWorkArea === Documents.WorkAreas.Kreis.ROOT && Config.entries.removeRootWorkArea) continue
					if (workarea._idWorkArea === Documents.WorkAreas.Kreis.CLIENT) continue
					_dataForm.addOption('workarea', { value: workarea._idWorkArea, text: workarea.name })
				}
			}
		},

		_onAllFetchsAfterLoginCompleted: function() {
			this._initDataTable()
		},

		_onResponsiveBreakpoint: function(breakpoint: number) {
			this._basicHeader.setStates<BasicHeaderRef['states']>({ isMobile: breakpoint <= Breakpoints.Medium })
			this._dataTable.setStates<DataTableRef['states']>({ stacked: breakpoint <= Breakpoints.Large })
			this._nestedDataTable.setStates<DataTableRef['states']>({ stacked: breakpoint <= Breakpoints.Large })
			this._popupUserForm._dataTable.setStates<DataTableRef['states']>({ stacked: breakpoint <= Breakpoints.Large })

			this.setStates<View21Ref['states']>({ dataTableParser: breakpoint <= Breakpoints.Large ? parseStackedDataResponse : parseDataResponse })
			this._initDataTable()
			this._updatePopupComponents(true)

			const { userStorages } = this.states
			this._initPopupUserFormDataTable(userStorages)
		},

		_resolveUsersPath(action: number) {
			return action === Component.Actions.INSERT
				? Server.Routes.Users.AddUser
				: Server.Routes.Users.UpdateUser
		},

		_updateDataForm: async function() {
			const { _dataForm } = this._popupUserForm
			const { documentToUpdate } = this.states

			// Eliminar valores no validos para el campo 'phone'.
			let filteredPhone = ''
			const _phone = documentToUpdate?.phone

			if (!PrimitiveTools.Arrays.isInvalidOrEmpty(_phone)) {
				filteredPhone = _phone[0].toString().replace('+56 ', '')
			}

			// Autocompletar los inputs al momento de editar.
			_dataForm.setValue('rut', documentToUpdate.rut)
			_dataForm.setValue('pLastName', documentToUpdate.pLastName)
			_dataForm.setValue('email', documentToUpdate.email)
			_dataForm.setValue('name', documentToUpdate.name)
			_dataForm.setValue('mLastName', documentToUpdate.mLastName)
			_dataForm.setValue('phone', filteredPhone)
			_dataForm.setValue('role', documentToUpdate._idRole)
			_dataForm.setValue('workarea', documentToUpdate._idWorkArea)
			_dataForm.setValue('isValid', documentToUpdate.isValid)

			// Si la fila contiene Ubicaciones, estás deben ser manipuladas desde un Array auxiliar para evitar errores de referencia.
			const { storages } = documentToUpdate
			if (storages?.length > 0) {
				// const userStorages = storages.map((x: string) => Store.getters.getStoredStorageById(x))
				this.setStates<View21Ref['states']>({ userStorages: documentToUpdate.storages })
				this._initPopupUserFormDataTable(documentToUpdate.storages)
			}
		},

		_updatePopupComponents: async function(preventShowPopupTable: boolean, page = 1, forceRefresh = false) {
			// Validaciones Opcionales según Casos.
			const { _dataForm } = this._popupUserForm
			if (!preventShowPopupTable) _dataForm.setStates<DataFormRef['states']>({ showPopupTable: true })
			if (forceRefresh) this._nestedDataTable.clearAll()
			this._nestedDataTable.setFetchingState()

			// Actualizar el titulo al componente 'PopupTable'.
			_dataForm._popupTable.setTitle('Selección Ubicación')

			// Eliminar los Registros si se fuerza una Actualización.
			if (forceRefresh) Store.commit('destroyStorages')
			
			// Aplicar Registros con Paginación.
			const response = await Store.dispatch('fetchStoragesForPage', { forceRefresh, page })
			const SummaryParser = DataParsers.Storages.GetSummaryParser(this._nestedDataTable.states.stacked)
			const { fields, items } = SummaryParser(response.data)
			this._nestedDataTable.updateElementsAndPagination(response.totalPages, fields, items)

			// Aplicar Orden para Columna especifica.
			this._nestedDataTable.resetEmptyText()
			this._nestedDataTable.sortOrder('code', 'asc')
		},

		onClose: function() {
			const { _dataForm, _dataTable } = this._popupUserForm
			this.setStates<View21Ref['states']>({ documentToUpdate: undefined, showPopupUserForm: false, userStorages: [] })
			_dataForm.clearInputs()
			_dataTable.clearData()
		},

		onDataFormSubmit: async function() {
			// Referencias a Componentes y Datos.
			const { documentToUpdate, userStorages } = this.states
			const { _dataForm } = this._popupUserForm
			const { action }    = _dataForm.states
			const isInserting   = action === Component.Actions.INSERT

			// Realizar validación de los Campos.
			if (!_dataForm.validateStates()) {
				this.showToast('Error de Validación', 'Verifica que ningun campo se encuentre marcado de color rojo.', 'danger')
				return
			}

			// Inputs del DataForm.
			const _idAdminCompany = Store.getters.getStoredUser._idAdminCompany
			const _idUser         = documentToUpdate?._idUser
			const { _idCompany }  = Store.getters.getStoredUser
			const _idRole         = _dataForm.getValue('role')
			const _idWorkArea     = _dataForm.getValue('workarea')
			const rut             = _dataForm.getValue('rut').replace(/\./g, '')
			const pLastName       = _dataForm.getValue('pLastName')
			const email           = _dataForm.getValue('email')?.toLowerCase()
			const password        = _dataForm.getValue('password')
			const name            = _dataForm.getValue('name')
			const mLastName       = _dataForm.getValue('mLastName')
			const phone           = [_dataForm.getElement<InputPhone>('phone').Value]
			const isValid         = _dataForm.getValue('isValid') === 'Sí' 
			const storages        = userStorages.map((x: any) => x._idStorage || x._id)

			// Objeto con las Propiedades requeridas por la Petición.
			const body = action === Component.Actions.INSERT ? {
				_idCompany, _idAdminCompany, _idRole, _idWorkArea, rut, pLastName, email, password, name, mLastName, phone, storages
			} : {
				_idUser, _idRole, _idWorkArea, rut, pLastName, email, name, mLastName, phone, isValid, storages
			}

			// Validación de los campos de la petición.
			const joiSchema = isInserting ? Module2.JoiSchemas.AddUser : Module2.JoiSchemas.UpdateUser
			const result = joiSchema.validate(body)
			if (result.error) return JoiManager.showToastOnError(this.showToast, `Error al ${ isInserting ? 'Crear un' : 'Actualizar el' } Usuario Interno`, result.error)

			// Bloquear el bóton submit hasta obtener una respuesta
			this._popupUserForm.setStates<PopupUserFormRef['states']>({ isFetching: true })

			// Realizar la Petición al servidor.
			const fetchAction = action === Component.Actions.INSERT ? Server.Fetching.Method.POST : Server.Fetching.Method.PATCH
			const response = await this.doFetch({ action: fetchAction, path: this._resolveUsersPath(action), body })

			// Si se obtiene una respuesta satisfactoria, continuar con el proceso.
			if (response.status === Server.Response.StatusCodes.SUCCESS) {
				if (action === Component.Actions.INSERT) {
					// Por Paginación, refrescar todos los Registros.
					this.onDTRefreshButtonClick()
					this.onClose()
					this.showToast('Creación de Registro', 'El registro a sido creado correctamente!', 'success')
				}
				else if (action === Component.Actions.UPDATE) {
					// Por Paginación, refrescar todos los Registros.
					this.onDTRefreshButtonClick()
					this.onClose()
					this.showToast('Actualización de Registro', 'El registro a sido actualizado correctamente!', 'success')
				}

				this._popupUserForm.setStates<PopupUserFormRef['states']>({ isFetching: false })
			}

			/////////////////////////////////////////////////////////////////////////////////////
			// PENDIENTE DE REFACTORIZAR
			/////////////////////////////////////////////////////////////////////////////////////
			else {
				const errorMessage = response.data.body.payload.message
				if (errorMessage.includes('rut')) {
					this.showToast('Error actualización', 'El Rut ya ha sido ingresado', 'danger')
				}
				else if (errorMessage.includes('email')) {
					this.showToast('Error actualización', 'El Email ya ha sido ingresado', 'danger')
				}
			}
		},

		onDTButtonClick: function(key: string, row: any) {
			// Inicializar Siempre que el formulario sea mostrado.
			this._initRoles()
			this._initWorkAreas()

			if (key === 'edit') {
				this._popupUserForm._dataForm.setStates<DataFormRef['states']>({ action: Component.Actions.UPDATE })
				this.setStates<View21Ref['states']>({ dataFormAction: Component.Actions.UPDATE, documentToUpdate: row.item, showPopupUserForm: true })
				this._updateDataForm()
			}
			else if (key === 'view') {

			}
		},

		onDTCurrentSearchPaginationChanged: function(page: number, searchParams: any) {
			this.onDTSearchButtonClicked(searchParams, page)
		},

		onDTExportDataClick: async function(format: string, filterType: AppValues.DataTableExportDataFilterType, filterValue: AppValues.QuantityChoiceList) {
			// Datos requeridos para obtener los Registros a Descargar.
			const { _idAdminCompany } = Store.getters.getStoredUser

			// Objeto con las Propiedades requeridas por la Petición.
			const params = {
				_idAdminCompany,
				isClient: false,
				itemsPerPage: 1,		// 'itemsPerPage' es Requerido en la Petición pero no es considerada.
				page: 1,				// 'page' es Requerido en la Petición pero no es considerada.
				quantity: filterValue
			}

			// 'Parser' utilizado para procesar la Respuesta de los Datos.
			const { dataTableParser } = this.states

			// Realizar la Petición al servidor.
			const response = await this.doFetch({ action: Server.Fetching.Method.GET, path: Server.Routes.Users.GetUsersByPage, params })

			// Si se obtiene una respuesta satisfactoria, continuar con el proceso.
			if (response.status === Server.Response.StatusCodes.SUCCESS) {
				// Respuesta de la Petición.
				const _response = response.data.body.data
				const { items } = dataTableParser(_response)
				this._dataTable.handleExportDataType(format, items)
			}
		},

		onDTPaginationChanged: async function(page: number) {
			this._initDataTable(page)
		},

		onDTRefreshButtonClick: async function() {
			Store.commit('destroyInternalUsers')
			this._initDataTable(1, true)
		},

		onDTSearchButtonClicked: function(searchParams: any, page = 1) {
			let { searchKey, searchValue } = searchParams
			const { _idAdminCompany } = Store.getters.getStoredUser

			// Campo Rut.
			let isRutValid = null as boolean
			if (searchKey === 'rut') isRutValid = RutTools.validator(searchValue)

			// Campo Fono.
			if (searchKey === 'phone') searchValue = searchValue.replace(' ', '')

			// Realizar Petición de Busqueda (y Realizar Formateo con Parser).
			this._dataTable.doInputSearch(Server.Routes.Users.GetUsersBySearchFilter,
				this.states.dataTableParser,
				{
					_idAdminCompany, isClient: false,
					itemsPerPage: this._dataTable.itemsPerPage, page,
					searchKey, searchValue: isRutValid ? RutTools.formatter(searchValue) : searchValue
				},
				(response) =>  response.data.body[0]
			)
		},

		onPTSelect: function(key: string, currentRow: any) {
			const { item } = currentRow
			const { _dataForm } = this._popupUserForm

			if (key === 'company') {
				_dataForm.setValue(key, currentRow.item._idCompany, '_id')
				_dataForm.setValue(key, currentRow.item.name, 'value')
			}
			else if (key === 'storage') {
				// Eliminar Propiedades que no prestan función.
				delete item.$region
				
				// Validar que no se ingresen multiples copias de una misma ubicación.
				const { userStorages } = this.states
				const found = userStorages.find((x: any) => (x._idStorage === item._idStorage) || (x._id === item._idStorage))
				if (found) return this.showToast('Ubicación ya ingresada', `La ubicación ${ item.code } ya se encuentra ingresada en la tabla!`, 'warning')
				
				// Agregar la fila al Array del objeto.
				userStorages.push(item)
				this._initPopupUserFormDataTable(userStorages)
			}

			// Forzar cierre del componente 'PopupTable'.
			_dataForm.setStates<DataFormRef['states']>({ showPopupTable: false })
		},

		onPUFButtonClick: function(key: string, row: any) {
			if (key === 'delete') {
				const { index } = row
				const { userStorages } = this.states
				userStorages.splice(index, 1)
				this._initPopupUserFormDataTable(userStorages)
			}
		},

		onPUFCurrentSearchPaginationChanged: function(page: number, searchParams: any) {
			this.onPUFSearchButtonClicked(searchParams, page)
		},

		onPUFNewButtonClick: function() {
			this._updatePopupComponents(false)
		},

		onPUFPaginationChanged: function(page: number) {
			this._updatePopupComponents(false, page)
		},

		onPUFRefreshButtonClick: function() {
			this._updatePopupComponents(false, 1, true)
		},

		onPUFSearchButtonClicked: function(searchParams: any, page = 1) {
			// Parametros.
			const { _idAdminCompany } = Store.getters.getStoredUser
			const { searchKey, searchValue } = searchParams
			const SummaryParser = DataParsers.Storages.GetSummaryParser(this._nestedDataTable._isStacked)
			
			// Cambio de Estado de Carga.
			this._nestedDataTable.setFetchingState()

			// Ejecución de Endpoint Buscar.
			this._nestedDataTable.doInputSearch(Server.Routes.Storages.GetStoragesBySearchFilter, SummaryParser,
				{
					_idAdminCompany,
					itemsPerPage: this._nestedDataTable.itemsPerPage,
					page,
					searchKey,
					searchValue,
				},
				(response) =>  response.data.body[0]
			)
		},

		onServerCaughtFetchException: function() {
			this._popupUserForm.setStates<PopupUserFormRef['states']>({ isFetching: false })
		},

		onServerFailedResponse: function() {
			this._popupUserForm.setStates<PopupUserFormRef['states']>({ isFetching: false })
		},

		onShowPopupUserForm: function() {
			this.setStates<View21Ref['states']>({ dataFormAction: Component.Actions.INSERT, showPopupUserForm: true })
			this._popupUserForm._dataForm.setStates<DataFormRef['states']>({action: this.states.dataFormAction})
			this._initRoles()
			this._initWorkAreas()
		}
	},

	watch: {
		...VuexTools.watchStoreProperty(Vuex.Modules.Global.Names.Permissions, '_initPermissions'),
		...VuexTools.watchStoreProperty(Vuex.Modules.Global.Names.Roles, '_initDataTable'),
		...VuexTools.watchStoreProperty(Vuex.Modules.Global.Names.Users, '_initDataTable'),
		...VuexTools.watchStoreProperty(Vuex.Modules.Global.Names.WorkAreas, '_initDataTable')
	}
})

// Exports
export default View21
export type View21Ref = InstanceType<typeof View21>
