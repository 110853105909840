// src
import Config from '@/config'

// Classes
import FieldsOptions         from '@/Classes/Records/FieldsOptions'
import { ItemMissingFields } from '@/Classes/Records/ItemMissingFields'
import { RecordHelper }      from '@/Classes/Records/RecordHelper'
import { BuilderManager }    from '@/Classes/Responses/BuilderManager'
import { PrimitiveTools }    from '@/Classes/Static/PrimitiveTools'

// Constants
import { ActionFields } from '@/Constants/Global/ActionFields'
import { AppValues }    from '@/Constants/Global/AppValues'
import { Documents }    from '@/Constants/Global/Documents'

export function parseDataResponse(data: Array<any>) {
	// Clase Constructora.
	const bm = new BuilderManager(data, ActionFields.Edit)
	const { fields, items, actions } = bm.getElements()

	// Eliminar los campos que no queremos mostrar en la tabla.
	bm.removeFields(fields, [
		'_idCompany', '_idUser', '_idRole', '_idWorkArea',
		'name', 'pLastName', 'mLastName', 'permissions', 'storages',
		'codeSAP', 'codeBranch', 'nameBranch', 'originSAP', 'systemValid'
	])

	// Aplicar opciones a los campos.
	bm.parseFieldsOptions(fields, new FieldsOptions()
		.add('rut', 'Rut').isSortable().setAlign('center', 'center')
		.add('mName', 'Nombre').isSortable().setSearchKey('user.name')
		.add('email', 'Correo').isSortable()
		.add('phone', 'Fono').isSortable()
		.add('companyName', 'Cliente').isSortable().setAlign(null, 'center').setSearchKey('company.name')
		.add('roleName', 'Rol Asignado').isSortable().setAlign(null, 'center').setSearchKey('role.name')
		.add('workAreaName', 'Área Trabajo').isSortable().setAlign(null, 'center').setSearchKey('workArea.name')
		.add('isValid', '¿Esta Activo?').setAlign('center', 'center')
	)

	// Aplicar un nuevo Orden a todas las columnas visibles.
	bm.rearrangeFields(fields, [
		'rut', 'mName', 'email', 'phone', 'companyName', 'storagesName', 'roleName', 'workAreaName', 'isValid'
	])

	// Aplicar un valor a los campos e items si no estan presentes.
	bm.parseItemsMissingFields(items, new ItemMissingFields()
		.add('mName')
	)

	// Eliminar los items que no queremos mostrar en la tabla.
	bm.removeItems(items, [
		{ if: Config.entries.removeRootUser, field: '_idRole', value: Documents.Roles.Kreis.ROOT }
	])

	// Definir los formatos que serán aplicados al valor de la celda.
	bm.parseItemsFormatters(items, {
		mName: (_: void, item: any) => {
			return `${ item.name } ${ item.pLastName }`
		},

		isValid: (_: void, item: any) => {
			return item.isValid ? 'Sí' : 'No'
		},

		phone: (values: Array<string> | string) => {
			if (Array.isArray(values)) return PrimitiveTools.Arrays.isInvalidOrEmpty(values) ? AppValues.Strings.DEFAULT_EMPTY_STRING : values
			if (typeof values === 'string') if (values !== '') return [values]
			return AppValues.Strings.DEFAULT_EMPTY_STRING
		}
	})

	// Retornar Objeto con los Campos, Items y Acciones.
	return { fields, items, actions }
}

export function parseStackedDataResponse(data: Array<any>) {
	// Clase Constructora.
	const bm = new BuilderManager(data)
	const { fields, items, actions } = bm.getElements()

	// Eliminar los campos que no queremos mostrar en la tabla.
	bm.removeFields(fields, [
		'_idUser', '_idCompany', '_idStorage', '_idRole', '_idWorkArea', 'roleName', 'workAreaName',
		'email', 'name', 'pLastName', 'phone', 'mLastName', 'permissions', 'companyName', 'storages', 'isValid',
		'codeSAP', 'codeBranch', 'nameBranch', 'originSAP', 'systemValid'
	])

	// Aplicar opciones a los campos.
	bm.parseFieldsOptions(fields, new FieldsOptions()
		.add('rut', 'Rut').ignoreSpecialChars().isSortable().setAlign('center', 'center').showDetailsButton()
		.add('mName', 'Nombre').isSortable().setSearchKey('user.name')
	)

	// Aplicar un nuevo Orden a todas las columnas visibles.
	bm.rearrangeFields(fields, [
		'rut', 'mName'
	])

	// Aplicar un valor a los campos e items si no estan presentes.
	bm.parseItemsMissingFields(items, new ItemMissingFields()
		.add('mName')
	)

	// Definir los formatos que serán aplicados al valor de la celda.
	bm.parseItemsFormatters(items, {
		mName: (_: void, item: any) => {
			return `${ item.name } ${ item.pLastName }`
		}
	})

	// Definir los campos a mostrar en el Detalle.
	bm.parseItemsDetail(items, {
		[RecordHelper.setFlags('Correo', 'email')]: ({ email }) => {
			return email
		},
		[RecordHelper.setFlags('Fono', 'phone')]: ({ phone }) => {
			return PrimitiveTools.Arrays.isInvalidOrEmpty(phone) ? AppValues.Strings.DEFAULT_EMPTY_STRING : phone
		},
		[RecordHelper.setFlags('Cliente', 'companyName', 'company.name')]: ({ companyName }) => {
			return companyName
		},
		[RecordHelper.setFlags('Rol Asignado', 'roleName', 'role.name')]: ({ roleName }) => {
			return roleName || AppValues.Strings.DEFAULT_EMPTY_STRING
		},
		[RecordHelper.setFlags('Área Trabajo', 'workAreaName', 'workArea.name')]: ({ workAreaName }) => {
			return workAreaName || AppValues.Strings.DEFAULT_EMPTY_STRING
		},
		['¿Esta Activo?']: ({ isValid }) => {
			return isValid ? 'Sí' : 'No'
		},
		['Acciones']: ActionFields.Edit
	})

	// Retornar Objeto con los Campos, Items y Acciones.
	return { fields, items, actions }
}